import { LinkText } from '@mediahuis/chameleon-react';
import type { ReactElement } from 'react';

import { brandedHeaderSublinkIcon, storyblockHeadingWithBottomGap } from '@hubcms/brand';
import { type GTitle, type GTitleOrGLogo, isGLogo, isGTitle } from '@hubcms/domain-teaser-grid';
import { BrandedHeadingSubtext, BrandedHeading } from '@hubcms/ui-branded-header';

import TeaserGridItem from '../lib/TeaserGrid/TeaserGridItem';
import { Logo } from '../ui';
import { parseLogo } from '../utils/parsers';
import { parseTitle } from '../utils/parsers/parseTitle';

function SubLinkComponent({ subLink }: { subLink: GTitle['data']['subLink'] }) {
  if (!subLink) {
    return null;
  }
  if (subLink.onClick) {
    return (
      <BrandedHeadingSubtext>
        <LinkText as="button" iconRight={brandedHeaderSublinkIcon} onClick={subLink.onClick}>
          {subLink.text}
        </LinkText>
      </BrandedHeadingSubtext>
    );
  }
  return (
    <BrandedHeadingSubtext>
      <LinkText href={subLink.url} target={subLink.target}>
        {subLink.text}
      </LinkText>
    </BrandedHeadingSubtext>
  );
}

type UseAreaTitleOptions = {
  isRoot?: boolean;
  className?: string;
};

function LogoPlaceholder() {
  return <div style={{ height: 32 }} />;
}

export function useAreaTitle(title?: GTitleOrGLogo | null, { isRoot = false, className }: UseAreaTitleOptions = {}) {
  let titleElement: ReactElement | null = null;
  if (title && isGTitle(title)) {
    const { componentProps } = parseTitle(title, 0);
    const gridProps = {
      hasInverseMarginBlockEnd: !storyblockHeadingWithBottomGap,
      ...title.gridProps,
    };
    titleElement = componentProps ? (
      <TeaserGridItem as="div" key={title.data.text} gridProps={gridProps} className={className}>
        <BrandedHeading {...componentProps} data-testid={isRoot ? 'storyblock-header' : undefined} />
        <SubLinkComponent subLink={title.data.subLink} />
      </TeaserGridItem>
    ) : null;
  }
  if (title && isGLogo(title)) {
    const { componentProps } = parseLogo(title, 0);

    titleElement = componentProps ? (
      <TeaserGridItem as="div" key={title.data.text} gridProps={title.gridProps || {}}>
        {/*
          We render a placeholder with the same height if a logo was provided but the url is falsy.
          The logo option in a grid item is currently only used by the RssArticleList type for which this behavior makes sense.
          If we ever allow conditional logo's for other grid items, pass null as title or rethink and refactor.
        */}
        {componentProps.url ? <Logo {...componentProps} /> : <LogoPlaceholder />}
      </TeaserGridItem>
    ) : null;
  }

  const hasTitle = Boolean(titleElement);

  return {
    titleElement,
    hasTitle,
  };
}
