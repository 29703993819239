import type { GLogo, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';

import { Logo } from '../../ui';

export const parseLogo: TeaserGridItemParserFn<GLogo, typeof Logo> = (item, idx) => ({
  key: `logo-${idx}`,
  Component: Logo,
  componentProps: {
    url: item.data.logo.url,
    alt: item.data.logo.alt ?? item.data.text ?? item.data.logo.url.split('/').pop() ?? '',
  },
});
