import cx from 'classnames';

import ListItem from './ListItem';
import styles from './list.module.scss';
import type { ListProps } from './types';

function List({ children, className, style, isOrdered, verticalAlign = 'top', mdTwoColumns, ...props }: ListProps) {
  const ListElement = isOrdered ? 'ol' : 'ul';

  return (
    <ListElement
      className={cx(className, styles.list, styles[`vertical-align-${verticalAlign}`], {
        [styles.orderedList]: isOrdered,
        [styles.mdTwoColumns]: mdTwoColumns,
      })}
      style={style}
      {...props}
    >
      {children}
    </ListElement>
  );
}

List.Item = ListItem;

export default List;
